<template>
  <div class="box col-wrapper">
    <div class="contain col-wrapper">
      <el-form inline>
        <el-form-item label="名称">
          <el-input v-model="treeFilter" clearable placeholder="请输入要搜索的类别名"></el-input>
        </el-form-item>
        <el-form-item>
          <Button type="success" @click="serchCategory">搜索</Button>
          <Button @click="resetForm">重置</Button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tree col-wrapper">
      <el-tree ref="tree"
               :data="treeData"
               :default-expand-all="false"
               :default-expanded-keys="[0]"
               :filter-node-method="filterNode"
               :props="treeOption"
               empty-text=""
               node-key="id">
        <template #default="{node,data}">
          <div class="custom-tree-node row-wrapper">
            <div class="treeLabel">{{ node.label }}</div>
            <div class="treeOption">
              <a
                  :class="{'append':node.level<=2,'appendDisable':node.level>2}"
                  class="option"
                  @click="addCategory(0,data.id,node.level)"> 新增子级 </a>
              <a v-if="data.id!==0" class="edit option" @click="editCategory(data,node)"> 修改 </a>
              <el-popconfirm
                  :title="'确认删除 '+data.name+' 吗？'"
                  cancel-button-text="取消"
                  confirm-button-text="确认"
                  @confirm="DeleteCategory(data.id)">
                <template #reference>
                  <a v-if="data.id!==0" class="delete option"> 删除 </a>
                </template>
              </el-popconfirm>
              <a v-if="data.status==='02'&&data.id!==0"
                 class="enable option"
                 @click="UpdateStatusForCategory(data.id, '01')"> 启用 </a>
              <a v-if="data.status==='01'&&data.id!==0"
                 class="ban option" @click="UpdateStatusForCategory(data.id,'02')"> 禁用 </a>
            </div>
          </div>
        </template>
      </el-tree>
    </div>
    <Dialog v-model="editVisiable" title="修改" @close="editVisiable=false">
      <el-form>
        <el-form-item label="父级类型">
          <el-cascader
              v-model="cascaderCurr"
              :options="cascaderData"
              :props="cascaderOption">
            <template #default="{node,data}">
              <div @click="selectAreaForEdit(node)">
                <span>{{ data.name }}</span>
              </div>
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item label="类型名称">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <Button type="primary" @click="SaveCategory">确定</Button>
        <Button @click="editVisiable=false">取消</Button>
      </template>
    </Dialog>
    <Dialog v-model="addVisiable" title="新增" @close="addVisiable=false">
      <el-form>
        <el-form-item label="名称">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <Button type="primary" @click="SaveCategory()">确定</Button>
        <Button @click="addVisiable=false">取消</Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import * as ResouceApi from "../../api/api/Resources"
import * as BasicDataApi from "../../api/api/BasicData"

export default {
  name: "Category",
  data() {
    return {
      // --- 树形选择器 ---
      treeOption: {
        children: 'childItem',
        label: 'name',
      },
      treeData: [],
      treeFilter: "", // 树形组件过滤
      // --- 弹窗 ---
      addForm: {
        name: undefined,
        id: 0, // 0 - 新增 其他 - 修改
        pid: undefined
      },
      cascaderOption: { // 三级联动选择器选项
        label: "name",
        children: "childItems",
        value: "id",
        checkStrictly: true,
      },
      cascaderCurr: [],
      editVisiable: false,
      addVisiable: false,
    }
  },
  computed: {
    cascaderData() {
      return [
        {
          childItems: this.treeData[0].childItem,
          id: 0,
          name: "全部",
          status: "01"
        }
      ]
    }
  },
  watch: {
    /**
     * 树形组件过滤
     * @param val
     */
    treeFilter(val) {
      if (val === "" || val === undefined) {
        this.$refs.tree.filter(val)
      }
    },
    /**
     * 多级选择菜单
     * @param val
     */
    cascaderCurr(val) {
      this.addForm.pid = val[val.length - 1]
    }
  },
  methods: {
    // --- 事件  ---
    /**
     * 选择区域 编辑
     * @param val
     */
    selectAreaForEdit(val) {
      switch (val.level) {
        case 1:
          this.cascaderCurr = [0]
          break
        case 2:
          this.cascaderCurr = [0, val.data.id]
          break
        default:
          break
      }
      console.log(this.cascaderCurr)
      this.addForm.pid = val.data.id
    },
    /**
     * 重置搜索条件
     */
    resetForm() {
      this.treeFilter = ""
    },
    /**
     * 查询类型
     */
    serchCategory() {
      this.$refs.tree.filter(this.treeFilter)
    },
    /**
     * 增加文章类别
     * @param id 0 - 新增  其他 - 修改对应id的类别
     * @param pid 父节点 id
     * @param level 节点级别
     */
    addCategory(id, pid, level) {
      if (level > 2) {
        return ''
      }
      this.addForm.id = id
      this.addForm.pid = pid
      this.addVisiable = true
    },
    /**
     * 修改类别
     */
    editCategory(data, node) {
      this.addForm.id = data.id
      this.addForm.pid = node.parent.data.id
      this.addForm.name = data.name
      switch (node.level) {
        case 2:
          this.cascaderCurr = [0]
          break
        case 3:
          this.cascaderCurr = [0, node.parent.data.id]
          break
        default:
          break
      }
      this.editVisiable = true
    },
    /**
     * 表格页大小
     * @param val
     */
    handleSizeChange(val) {
      this.page.limit = val
      this.page.page = 1
    },
    /**
     * 翻页
     * @param val
     */
    handleCurrentChange(val) {
      this.page.page = val
    },
    /**
     * 节点过滤
     * @param value 过滤值
     * @param data 节点数据 label value
     * @return {boolean|*}
     */
    filterNode(value, data) {
      if (!value) return true
      return data.name.includes(value)
    },
    /**
     * 删除节点
     * @param val
     * @param data
     */
    remove(val, data) {
      console.log("node", val.level)
      console.log(data.$treeNodeId)
    },
    //  --- axios ---
    /**
     * 获取类别
     * @constructor
     */
    GetCategoryList() {
      const pd = {}
      ResouceApi.GetCategoryList(pd)
          .then(res => {
            if (res.data.code === 200) {
              this.treeData = [{
                childItem: res.data.data,
                id: 0,
                name: "全部",
                status: "01"
              }]
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("获取类别失败")
          })
    },
    /**
     * 启用/禁用文章类别
     * @param id
     * @param status
     * @constructor
     */
    UpdateStatusForCategory(id, status) {
      let pd = {id, status}
      BasicDataApi.UpdateStatusForCategory(this.$qs.stringify(pd))
          .then(res => {
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
              this.GetCategoryList()
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("修改文章类别启用禁用状态失败")
          })
    },
    /**
     * 修改或保存文章类型
     * 参数为 this.addForm
     * @constructor
     */
    SaveCategory() {
      BasicDataApi.SaveCategory(this.$qs.stringify(this.addForm))
          .then(res => {
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
              this.addForm = {
                name: undefined,
                id: 0, // 0 - 新增 其他 - 修改
                pid: undefined
              }
              this.editVisiable = false
              this.addVisiable = false
              this.GetCategoryList()
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("保存文章类型失败")
          })
    },
    /**
     * 删除文章类型
     * @param id
     * @constructor
     */
    DeleteCategory(id) {
      const pd = {id}
      BasicDataApi.DeleteCategory(this.$qs.stringify(pd))
          .then(res => {
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
              this.GetCategoryList()
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("文章类别删除失败")
          })
    }
  },
  mounted() {
    this.GetCategoryList()
  }
}
</script>
<style>
.el-radio {
  display: none;
}
</style>
<style lang="less" scoped>
.box {
  justify-content: stretch;

  ::v-deep(.el-cascader) {
    width: 100%;
  }

  .tree {
    height: 75vh;
    overflow-x: hidden;
    overflow-y: scroll;
    align-items: stretch;
    padding: 5px;

    ::v-deep(.el-tree-node) {
      width: 35vw;
    }

    .custom-tree-node {
      justify-content: space-between;
      width: 100%;

      .treeLabel {
        //margin-right: 10vw;
        width: 64px;
      }

      .treeOption {
        width: 195px;

        .option {
          margin-right: 5px;
        }

        .appendDisable {
          color: #b7b6b6;
        }

        .appendDisable:hover {
          cursor: not-allowed;
        }

        .append {
          color: #409eff;
        }

        .edit {
          color: #409eff
        }

        .delete {
          color: #D9001B
        }

        .enable {
          color: #70B603
        }

        .ban {
          color: #F59A23
        }
      }
    }

  }

  .tree::-webkit-scrollbar {
    width: 10px;
  }

  .tree::-webkit-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #409eff;
  }

  .contain {
    width: 83%;
  }
}
</style>
